/** @format */

import React, { useState } from "react";
import logo2 from "../../assets/images/header-logo.png";
// import logo2 from "../../assets/images/CONNECT CARE TRANS-04.png"
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04 (1).png"
import { useDispatch, useSelector } from "react-redux";
import {
  tabContactUs,
  tabFaq,
  tabHome,
  tabBooking,
  tabServices,
  tabSignIn,
  tabWhyUs,
} from "../../action/tabActions";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div className="axistify-header-section">
        <div className="axistify-header">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-md-6">
                <div className="tophead-text d-flex">
                  <span className="d-none d-md-block">
                    <i className="fa fa-phone mx-2 ico-col"></i>(773) 340-4373
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="element">
                  <ul className="header-top-nav list-inline">
                    <li className="menu-item">
                      <span className="d-none d-lg-block">
                        <a
                          href="https://www.facebook.com/connectcaretrans/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="f-15 fa fa-facebook-square mx-2 ico-col"
                            style={{ lineHeight: "25px" }}
                          ></i>
                        </a>
                      </span>
                    </li>
                    <li className="menu-item">
                      <span className="d-none d-lg-block">
                        <a
                          href="https://www.linkedin.com/company/connect-care-trans/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="f-15 fa fa-linkedin-square mx-2 ico-col"></i>
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="">
            <div className="paraxdo-header-img">
              <nav className="navbar sticky-top navbar-expand-lg bg-dark">
                <div className="container">
                  <NavLink className="navbar-brand" to="/">
                    <img src={logo2} id="logsedf" alt="" />
                  </NavLink>
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </button>
                  <div
                    className={`collapse navbar-collapse ${
                      isMenuOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <NavLink
                          to="/"
                          className={`newNavLink ${
                            myState.viewNavBar === "home" ? "active-3" : ""
                          }`}
                          onClick={() => dispatch(tabHome())}
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/about-us"
                          className={`newNavLink ${
                            myState.viewNavBar === "whyus" ? "active-3" : ""
                          }`}
                          onClick={() => dispatch(tabWhyUs())}
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/services"
                          className={`newNavLink ${
                            myState.viewNavBar === "services" ? "active-3" : ""
                          }`}
                          onClick={() => dispatch(tabServices())}
                        >
                          Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/booking"
                          className={`newNavLink ${
                            myState.viewNavBar === "booking" ? "active-3" : ""
                          }`}
                          onClick={() => dispatch(tabBooking())}
                        >
                          Booking
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/contact-us"
                          className={`newNavLink ${
                            myState.viewNavBar === "contactus" ? "active-3" : ""
                          }`}
                          onClick={() => dispatch(tabContactUs())}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NavBar;
