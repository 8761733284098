import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (fields, values) => {
  const doc = new jsPDF();

  // Title
  doc.setFontSize(18);
  doc.setFont("Roboto", "bold");
  doc.text("Terms and Conditions – Medical Transportation", 105, 20, {
    align: "center",
  });

  // Subtitle
  doc.setFontSize(14);
  doc.setFont("Roboto", "normal");
  doc.text("(Connect Care Trans LLC)", 105, 30, { align: "center" });

  // Dotted Line
  doc.setLineWidth(1);
  doc.setDrawColor(0);
  //   doc.setDash(2, 2);
  doc.line(20, 35, 190, 35);

  // Content
  const content = [
    "The following constitute the terms and conditions (the “Terms and Conditions”) to which you (“You”, “Your”, or “Passenger”) agree to be bound when booking any non-emergency transportation services (the “Services”) with Connect Care Trans LLC (“we”, “our”, “us”, or “Connect Care Trans”). If you do not have such authority, or if you do not agree with these Terms and Conditions, you must not accept these Terms and Conditions and may not use our Services.",
    "By accepting these Terms and Conditions, either by clicking a box indicating your acceptance, or by executing a copy of these Terms and Conditions, or by booking and utilizing the Service via phone with a copy of these Terms and Conditions provided to you, you agree to be bound by this Terms and Conditions.",
    // Add more paragraphs as needed
  ];

  let y = 45;
  content.forEach((text) => {
    doc.setFontSize(12);
    doc.setFont("Roboto", "normal");
    doc.text(text, 10, y);
    y += 10;
  });

  // Save the document
  doc.save("Terms_and_Conditions.pdf");
};

const SignPdf2 = ({ fields, values }) => {
  return (
    <button onClick={() => generatePDF(fields, values)}>Download PDF</button>
  );
};

export default SignPdf2;
