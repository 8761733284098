/** @format */

import React from "react";
import { Routes, Route } from "react-router-dom";

// import "./assets/css/style.css"
// import "./assets/css/font-awesome.css"
// import "./assets/css/font-awesome.min.css"
// import "./assets/fonts/fontawesome-webfont.eot"
// import "./assets/fonts/fontawesome-webfont.svg"
// import "./assets/fonts/fontawesome-webfont.ttf"
// import "./assets/fonts/fontawesome-webfont.woff"
// import "./assets/fonts/fontawesome-webfont.woff2"

import "./assets/css/all.min.css";
import "./assets/css/bootstrap-icons.css";
import "./assets/css/bootstrap-timepicker.min.css";
import "./assets/css/datepicker.min.css";
import "./assets/css/font-awesome.css";
import "./assets/css/font-awesome.min.css";
// important ha neecha wali
import "./assets/css/main.css";
import "./assets/css/main.css.map";
import "./assets/css/main.min.css";
import "./assets/css/select2.min.css";
import "./assets/css/simple-line-icons.css";
import "./assets/css/custom-css.css";
// import "./assets/css/login.css";

import "./assets/css/style.css";
// import "./assetsgeneral/css/style.css";

import { useSelector } from "react-redux";
import Home from "./pages/Home";
import WhyUs from "./pages/landingWebsitePages/WhyUs";
import Services from "./pages/landingWebsitePages/Services";
import ContactUs from "./pages/landingWebsitePages/ContactUs";
import BookingOnlinePage from "./pages/landingWebsitePages/BookingOnlinePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicyPage from "./pages/landingWebsitePages/PrivacyPolicyPage";
import Unauthorized from "./pages/Unauthorized";
import SupportChatPopup from "./components/SupportChatPopup";
import Signup from "./pages/landingWebsitePages/Signup";
import SignTermsAndConditions from "./pages/landingWebsitePages/SignTermsAndConditions";

const App = () => {
  // const auth = useSelector((state) => state.auth);

  return (
    <>
      <ToastContainer />
      {/* <SupportChatPopup /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<WhyUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/booking" element={<BookingOnlinePage />} />
        <Route path="/sign-up" element={<Signup />} />
        {/* <Route path="/index" element={<Home />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        {/* <Route path="/sign-term" element={<SignTermsAndConditions />} /> */}
        {/* <Route path="/support" element={<SupportChatPopup />} /> */}
        {/* <Route path="/access-denied" element={<AccountDetailsPage />} /> */}
        <Route path="*" element={<Unauthorized />} />
      </Routes>
    </>
  );
};
export default App;
