import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", // Regular font
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", // Bold font
      fontWeight: "bold",
    },
  ],
});
// Create a StyleSheet for your content
const styles = StyleSheet.create({
  sectionTitle: {
    textAlign: "center",
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 14,
    marginTop: 10,
  },
  dottedLine: {
    borderTop: "1px dashed black",
    marginTop: 10,
    width: "100%",
  },
  paragraph: {
    marginTop: 10,
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  boldUnderlined: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    textDecoration: "underline",
  },
  underlined: {
    textDecoration: "underline",
  },
  boldAndSize: {
    // marginTop: 40,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  inputFieldContainer: {
    marginBottom: 15,
  },
  inputLabel: {
    fontWeight: "bold",
    fontSize: 12,
    marginBottom: 5,
  },
  inputField: {
    fontSize: 12,
    borderBottomWidth: 2,
    paddingBottom: 2,
    marginBottom: 10,
  },
  activeInputField: {
    borderColor: "red",
  },
  defaultInputField: {
    borderColor: "black",
  },
});

const SignPdf = ({ fields, values }) => {
  return (
    <Document>
      <Page size="A4" style={{ padding: 20 }}>
        <View style={styles.sectionTitle}>
          <Text style={styles.title}>
            Terms and Conditions – Medical Transportation
          </Text>
          <Text style={styles.subtitle}>(Connect Care Trans LLC)</Text>
          {/* Dotted Line */}
          <View style={styles.dottedLine}></View>
        </View>
        <View>
          <Text style={styles.paragraph}>
            The following constitute the terms and conditions (the{" "}
            <Text style={styles.bold}>“Terms and Conditions”</Text>) to which
            you (<Text style={styles.bold}>“You”</Text>,{" "}
            <Text style={styles.bold}>“Your”</Text>, or{" "}
            <Text style={styles.bold}>“Passenger”</Text>) agree to be bound when
            booking any non-emergency transportation services (the{" "}
            <Text style={styles.bold}>“Services”</Text>) with Connect Care Trans
            LLC (<Text style={styles.bold}>“we”</Text>,{" "}
            <Text style={styles.bold}>“our”</Text>,{" "}
            <Text style={styles.bold}>“us”</Text>, or{" "}
            <Text style={styles.bold}>“Connect Care Trans”</Text>). If you do
            not have such authority, or if you do not agree with these Terms and
            Conditions, you must not accept these Terms and Conditions and may
            not use our Services.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            By accepting these Terms and Conditions, either by clicking a box
            indicating your acceptance, or by executing a copy of these Terms
            and Conditions, or by booking and utilizing the Service via phone
            with a copy of these Terms and Conditions provided to you, you agree
            to be bound by this Terms and Conditions.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Booking.</Text> All Services
            must be booked online or via phone. Bookings must include accurate
            pickup and drop-off locations, as well as any other information and
            requirements specified by us. You will receive a confirmation email
            upon successfully booking a trip.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Services.</Text>The Services are
            only provided at the time specified by you and do not include any
            emergency medical transportation services. The determination of
            whether a transport is an{" "}
            <Text style={styles.bold}>“emergency”</Text> or{" "}
            <Text style={styles.bold}>“non-emergency”</Text> shall be made by us
            in our sole discretion. We reserve the right to refuse to provide
            the Services to any passenger who fails to comply with these Terms
            and Conditions, exhibits inappropriate behavior, or we determine as
            unfit for the Services in our sole discretion.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Fees and Payment.</Text> We
            shall charge the fees as set forth on Exhibit A for the Services
            provided hereunder (the <Text style={styles.bold}>“Fees”</Text>). We
            shall have the right to change the Fees upon prior notice to you. We
            accept the payment of the Fees at the time of booking through credit
            cards and other online payment methods listed on our website.
            Payment must be completed in full to confirm a trip.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Refund Policy.</Text>Refunds
            will only be issued if the trip is canceled by us or if a
            cancellation is made by you in accordance with our cancellation
            policy specified in these Terms and Conditions. No refund will be
            issued for no-shows.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Cancellation.</Text>
            Cancellations must be made at least 2 hours prior notice to the
            scheduled trip, in order for you to receive a refund for your trip.
            If a cancellation is made within 2 hours of the scheduled trip, we
            will charge a cancellation fee of $60 dollars.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>No Show.</Text>If the passenger
            fails to appear at the designated pickup location within 10 minutes
            of the scheduled pick-up time, it will be considered a{" "}
            <Text style={styles.bold}>“no-show”</Text>. No refund will be issued
            for no- shows, and additional charges may apply if rescheduling is
            requested.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Special Accommodations.</Text>We
            are happy to accommodate you with your special needs or disability
            to the extent of our available resources and capabilities. However,
            you are responsible for notifying us of any specific accommodation
            required at the time of scheduling the trip. The driver will make
            reasonable efforts to provide the requested accommodations, however,
            providing such accommodations is not guaranteed.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Equipment.</Text>You may request
            the use of additional equipment for your trip (wheelchair, child car
            seat, booster seat, oxygen tank, etc.) for an additional fee. Such
            request must be made in advance at the time of booking. The
            availability of any equipment is not guaranteed for every trip. All
            equipment provided remains the property of us and must be returned
            in the same condition at the end of your trip. You will be
            responsible for any damage, loss, or theft of the equipment during
            the trip and will be charged a fee to cover the cost of repair or
            replacement
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Passenger Obligations.</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            a. You agree to pay the Fees in full at the time of booking your
            trip and in compliance with these Terms and Conditions.
          </Text>
          <Text style={styles.paragraph}>
            b. Prior to the scheduled trip, you must disclose any relevant
            medical conditions, special needs or requirements, including but not
            limited to, mobility aids, allergies, oxygen equipment or other
            equipment. Please note that we may not be able to accommodate
            certain requests without prior notice and approval.
          </Text>
          <Text style={styles.paragraph}>
            c. You are expected to behave in a respectful and safe manner during
            the trip. We reserve the right to refuse Services or remove any
            Passenger whose behavior is disruptive, harmful, or poses a risk to
            others, without any refund or compensation to you.
          </Text>
          <Text style={styles.paragraph}>
            d. If the Passenger is a minor, a legal guardian must complete and
            sign the Consent Form prior to the scheduled trip.
          </Text>
          <Text style={styles.paragraph}>
            e. Smoking, eating, and drinking are prohibited in the vehicle
            unless there is a disclosed medical condition that requires it.
          </Text>
          <Text style={styles.paragraph}>
            f. The Passenger must comply with all local, state, and federal laws
            during the trip.
          </Text>
          <Text style={styles.paragraph}>
            g. The Passenger is responsible for ensuring the belongings are
            secured during the trip. We are not responsible for lost or damaged
            items.
          </Text>
          <Text style={styles.paragraph}>
            h. You agree to be bound by the refund and cancellation policies
            outlined in these Terms and Conditions.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>
              Limitation of Liability and Liability Waiver.
            </Text>
            To the fullest extent permitted by applicable law and regulation, in
            no event shall Connect Care Trans or any of its members, managers,
            officers, employees, agents, drivers, or contractors be liable to
            you or any third party for any direct, indirect, consequential,
            incidental, or punitive damages relating to the provision of the
            Services, regardless of whether such damages are foreseeable or
            whether Connect Care Trans has been advised of the possibility of
            such damages, except where such damages or injuries are caused by
            the gross negligence or willful misconduct of Connect Care Trans.
            Further, Connect Care Trans shall not be responsible for delays or
            interruptions in Services due to traffic, weather, road conditions,
            or other circumstances beyond its control. You acknowledge and agree
            that by using the Services, you, and your guardians, agree to waive,
            release, and hold harmless Connect Care Trans from any claims
            demands, or liabilities arising from any injuries, damages, or
            losses incurred during the trip, to the fullest extent permitted by
            applicable law, except those resulting from the gross negligence or
            willful misconduct of Connect Care Trans.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>Indemnification.</Text>
            You shall indemnify, defend, and hold harmless Connect Care Trans
            and its members, managers, employees, agents, or representatives (
            <Text style={styles.bold}>“Indemnified Party”</Text>) against any
            and all losses, damages, liabilities, deficiencies, claims, actions,
            judgments, settlements, interest, penalties, fines, costs, or
            expenses of whatever kind, including reasonable attorneys’ fees,
            that are incurred by Indemnified Party (collectively,{" "}
            <Text style={styles.bold}>“Losses” </Text>), arising out of or
            related to any claim alleging (1) breach of these Terms and
            Conditions by you, (2) any bodily injury, death of any person or
            damage to real and tangible property caused by the negligent action
            or omission of you, and (3) failure by you to comply with any
            applicable law or regulation. This indemnification obligation shall
            survive the termination of Services.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            <Text style={styles.boldUnderlined}>General Provisions.</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            (a) <Text style={styles.underlined}>Entire Agreement.</Text> The
            Terms and Conditions, including and together with any related
            exhibits, schedules, and attachments constitute the sole and entire
            agreement of the parties with respect to the subject matter
            contained herein, and supersedes all prior and contemporaneous
            understandings, agreements, representations, and warranties, both
            written and oral, regarding such subject matter.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (b) <Text style={styles.underlined}>Assignment.</Text> While
            Passenger may not assign the right and obligation under these Terms
            and Conditions to any third party, Connect Care Trans may assign its
            rights to a third party, provided that such assignment shall not
            affect the provision of the agreed upon Services and other
            obligations under these Terms and Conditions.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (c) <Text style={styles.underlined}>No Waiver.</Text> No waiver by
            any party of any of the provisions of these Terms and Conditions
            shall be effective unless explicitly set forth in writing and signed
            by the party so waiving. No failure to exercise any right or remedy
            arising from these Terms and Conditions shall be construed as a
            waiver of any other right and remedy.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (d){" "}
            <Text style={styles.underlined}>Relationship of the Parties.</Text>{" "}
            Nothing contained in these Terms and Conditions shall be construed
            as creating any agency, partnership, joint venture, or other form of
            joint enterprise, employment, or fiduciary relationship between the
            parties, and neither party shall have the authority to contract for
            or bind the other party in any manner whatsoever.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (e) <Text style={styles.underlined}>Survival.</Text> The provisions
            of these Terms and Conditions shall survive the termination of these
            Terms and Conditions to the extent necessary to effectuate the terms
            contained herein.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (f) <Text style={styles.underlined}>Dispute Resolution.</Text> In
            the event of any controversy or claim arising out of or relating to
            the Terms and Conditions, the parties hereto shall consult and
            negotiate with each other in good faith and attempt to reach a
            solution satisfactory to both parties. If the parties cannot agree
            to a resolution within 30 days after the aggrieved party’s written
            notice to the other party, the terms of this provision shall be
            deemed fulfilled.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (g){" "}
            <Text style={styles.underlined}>
              Governing Law; Venue; Waiver of Jury Trial.
            </Text>{" "}
            The Terms and Conditions shall be governed by and construed in
            accordance with the laws of the State of Illinois (without giving
            effect to its laws of conflicts). The parties agree that any legal
            action or proceeding with respect to any of their respective
            obligations under these Terms and Conditions shall be brought
            exclusively in the County of Cook, State of Illinois. THE PARTIES
            HERETO HEREBY IRREVOCABLY WAIVE ANY AND ALL RIGHT TO TRIAL BY JURY
            IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THE TERMS AND
            CONDITIONS.
          </Text>{" "}
          <Text style={styles.paragraph}>
            (h) <Text style={styles.underlined}>Severability.</Text> If any
            provision of these Terms and Conditions is held invalid or
            unenforceable by any court of competent jurisdiction, the other
            provisions will remain in full force and effect.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.paragraph}>
            <Text style={styles.boldAndSize}>AGREED AND ACCEPTED BY:</Text>
          </Text>
        </View>
        <View>
          {fields.map((field, index) => (
            <View key={index}>
              <Text style={styles.paragraph}>
                <Text style={styles.boldUnderlined}>{field}:</Text>
              </Text>
              <Text style={styles.paragraph}>
                <Text style={styles.underlined}>{values[field]}:</Text>
              </Text>
            </View>
            // <View key={index} style={styles.inputFieldContainer}>
            //   <Text style={styles.inputLabel}>{field}:</Text>
            //   <Text
            //     style={[
            //       styles.inputField,
            //       //   index === currentStep
            //       //     ? styles.activeInputField
            //       //     : styles.defaultInputField,
            //     ]}
            //   >
            //     {values[field] || "_______________________"}
            //   </Text>
            // </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default SignPdf;
